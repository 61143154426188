/* eslint-disable max-lines */
import { Button, Collapse, Layout, Menu } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';
// import { LanguageSwitcher } from 'src/components/LanguageSwitcher/LanguageSwitcher';
import { Logo } from 'src/components/Logo';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { CloseMenuIcon } from 'src/icons/CloseMenuIcon/CloseMenuIcon';
import { MenuIcon } from 'src/icons/MenuIcon/MenuIcon';
import { AuthStore } from 'src/stores/AuthStore/AuthStore';
import { ProcessDefinitionsStore } from 'src/stores/ProcessDefinitionsStore/ProcessDefinitionsStore';
import { RouterStore } from 'src/stores/RouterStore.ts/RouterStore';
import { CssVariables } from 'src/styles/CssVariables';
import { UrlHelper } from 'src/utils/UrlHelper';
import styled from 'styled-components';

const { Header: AntHeader } = Layout;
export const HeaderMobile = observer(() => {
    const { t } = useTranslation();
    const [isOpenMenu, setIsOpenMenu] = useState(false);
    useEffect(() => {
        const mainContainer = document.querySelector(
            '.main-content',
        ) as HTMLElement;
        if (isOpenMenu) {
            mainContainer.style.display = 'none';
        } else {
            mainContainer.style.display = 'block';
        }

        return () => {
            mainContainer.style.display = 'block';
        };
    }, [isOpenMenu]);

    const handleBtnMenuClick = () => {
        setIsOpenMenu(!isOpenMenu);
    };

    const closeMenu = () => {
        setIsOpenMenu(false);
    };

    return (
        <>
            <StyledHeader>
                <Logo />

                <StyledButtonContainer>
                    {/* <LanguageSwitcher /> */}
                    <StyledButton onClick={handleBtnMenuClick} type="link">
                        {isOpenMenu ? <CloseMenuIcon /> : <MenuIcon />}
                    </StyledButton>
                </StyledButtonContainer>
            </StyledHeader>
            <StyledCollapse
                activeKey={isOpenMenu ? ['1'] : undefined}
                collapsible="header"
            >
                <Collapse.Panel header={null} key="1" showArrow={false}>
                    <StyledMenu onClick={closeMenu} activeKey="">
                        {RouterStore.menuItems.map((router) => {
                            return (
                                <Menu.Item key={router.key}>
                                    <NavLink
                                        to={router.path}
                                        activeClassName="active"
                                    >
                                        {router.menuTitle?.()}
                                    </NavLink>
                                </Menu.Item>
                            );
                        })}
                        <Menu.Item key={RoutePaths.profile}>
                            <NavLink to={RoutePaths.profile}>
                                {t('App.Header.Menu.Profile')}
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item
                            onClick={() => {
                                AuthStore.logout();
                            }}
                            key="logout"
                        >
                            <TextMuted>{t('App.Header.LogOut')}</TextMuted>
                        </Menu.Item>
                        <SpaceMenuItem key="empty" />
                        <StyledProcessMenuItem>
                            {ProcessDefinitionsStore.mainMenuProcesses?.map(
                                (process) => (
                                    <StyledApplyButton
                                        to={UrlHelper.getTargetUrl(
                                            RoutePaths.process,
                                            {
                                                processKey: process.key!,
                                            },
                                        )}
                                        key={process.id}
                                    >
                                        {t(
                                            `${process.extensions?.l10nTitleKey}`,
                                        )}
                                    </StyledApplyButton>
                                ),
                            )}
                        </StyledProcessMenuItem>
                    </StyledMenu>
                </Collapse.Panel>
            </StyledCollapse>
        </>
    );
});

const StyledApplyButton = styled(Link)`
    background: var(--form-button-color) !important;
`;

const StyledButtonContainer = styled.div`
    display: flex;
    align-items: center;
`;

const TextMuted = styled.span`
    color: #828b94;
`;

const SpaceMenuItem = styled(Menu.Item)`
    flex: 1;
    touch-action: none;
    cursor: default;
    pointer-events: none;
`;

const StyledCollapse = styled(Collapse)`
    position: fixed;
    width: 100vw;
    top: ${CssVariables.headerHeight};
    z-index: 100;
    border: none;
    background-color: white;
    > .ant-collapse-item {
        border: none;
    }

    .ant-collapse-header {
        display: none !important;
    }

    .ant-collapse-content {
        border-top: none;
        height: calc(100vh - ${CssVariables.headerHeight});
        .ant-collapse-content-box {
            position: relative;
            height: 100%;
            padding: 0 20px 30px;
        }
    }
`;

const StyledMenu = styled(Menu)`
    border-right: none;
    height: 100%;
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    .ant-menu-item {
        font-size: 21px;
        line-height: 150%;
        margin-bottom: 24px !important;
        &:active,
        &:hover,
        &.ant-menu-item-selected {
            background: transparent !important;
            color: #0d0f1c;
            text-decoration: none;

            a {
                color: #0d0f1c;
                text-decoration: none;
            }
        }
    }
`;

const StyledHeader = styled(AntHeader)`
    display: flex;
    color: #0d0f1c;
    align-items: center;
    background: white;
    box-shadow: 0 4px 4px rgba(222, 222, 222, 0.25);
    position: sticky;
    top: 0;
    z-index: 100;
    margin-bottom: 50px;
    padding: 0 20px;
    justify-content: space-between;
`;

const StyledProcessMenuItem = styled(Menu.Item)`
    height: auto !important;
    &:hover,
    &:active,
    &.ant-menu-item-selected {
        color: white !important;
        a {
            color: white !important;
            text-decoration: none;
        }
    }

    a {
        text-align: center;
        display: block;
        color: white;
        background: #0a65ed;
        height: 48px;
        border-radius: 8px;
        text-decoration: none;
        padding: 7px;
        margin-bottom: 50px;
        &:hover,
        &:active,
        &:focus {
            color: white;
            background: #0a65ed;
            text-decoration: none;
        }
    }
`;

const StyledButton = styled(Button)`
    padding: 8px 0 10px 15px;
`;
