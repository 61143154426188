import { LoanBorrowerAllowed } from 'src/generated-api-client';
import { borrowerApi, bpmsResourcesManagerApi } from 'src/services/apiServices';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

export class LoansStoreClass extends BasicStore<LoanBorrowerAllowed> {
    formKey = 'embedded:deployment:forms/bp/bp-15-deal-info.json';
    api: BasicStoreApi<LoanBorrowerAllowed> = {
        loadList: async (...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            const response = await RequestHelper.unwrapFromAxiosPromise(
                borrowerApi.getBorrowerLoans(0, 100, undefined, options),
            );

            return response.content || [];
        },
        loadItem: (id: number, ...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            return RequestHelper.unwrapFromAxiosPromise(
                borrowerApi.getBorrowerLoan(id, options),
            );
        },

        loadItemForm: (...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            return RequestHelper.unwrapFromAxiosPromise(
                bpmsResourcesManagerApi.getForm(this.formKey, options),
            );
        },
    };
}

export const LoansStore = new LoansStoreClass();
