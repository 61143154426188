import { Spinner } from 'src/components/Spinner/Spinner';
import { LOGO_LOCAL_STORAGE_KEY } from 'src/pages/StylerPage/StylerPage';
import { ThemeStore } from 'src/stores/ThemeStore/ThemeStore';
import { LocalStoreHelper } from 'src/utils/LocalStoreHelper';
import styled from 'styled-components';

const logoFromLocalStorage = LocalStoreHelper.getItem(LOGO_LOCAL_STORAGE_KEY);

export const SplashScreen = () => {
    return (
        <StyledAppSplashScreen>
            <StyledWrapper>
                {logoFromLocalStorage ? (
                    <StyledImage
                        maxWidth={ThemeStore.getLogoMaxSize}
                        src={logoFromLocalStorage}
                    />
                ) : (
                    <>
                        <StyledLogo>WAYFINA</StyledLogo>{' '}
                        <StyledTradeMark>®</StyledTradeMark>
                    </>
                )}
            </StyledWrapper>
            <Spinner />
        </StyledAppSplashScreen>
    );
};

const StyledAppSplashScreen = styled.div`
    display: flex;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const StyledLogo = styled.span`
    font-weight: bold;
    font-size: 32px;
`;

const StyledWrapper = styled.div`
    color: #0d0f1c;
    display: flex;
    margin-bottom: 30px;

    &:hover {
        text-decoration: none;
        color: #0d0f1c;
    }
`;
const StyledTradeMark = styled.span`
    transform: translate(3px, -3px);
`;

const StyledImage = styled.img<{ maxWidth?: string }>`
    max-width: ${(props) => props.maxWidth};
`;
